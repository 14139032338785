
  import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue';
  import { useToast } from "vue-toastification";

  import GoalForm from "@/views/admin/goals/components/GoalForm.vue";
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IPredefinedGoalForm, ICategory, ICategoryRes, IPredefinedGoalResponse } from '@/types';
  import { vuex } from "@/store";
  import { router, routesNames } from '@/router';
  import { myGoalsService, categoriesService } from "@/services";
  import { CATEGORIES } from '@/views/admin/goals/api-params';

  export default defineComponent({
    name: 'AddGoal',

    components: { GoalForm, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const state = reactive({
        categoriesList: [] as ICategory[],
        goalForm: {
          title: '',
          tasks: [],
          category: {} as ICategory
        } as IPredefinedGoalForm
      });

      const disabledHandleChanges = ref<boolean>(false);

      const { categoriesList, goalForm } = toRefs(state);

      async function onSubmit(payload: IPredefinedGoalResponse) {
        vuex.setLoading(true);

        await myGoalsService.createPredefinedGoal(payload)
          .then(() => {
            toast.success('Goal has been created');
            disabledHandleChanges.value = true;
            router.push({ name: routesNames.adminGoals });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchCategories() {
        vuex.setLoading(true);
        await categoriesService.fetchCategories(CATEGORIES)
          .then((res: ICategoryRes) => { categoriesList.value = res.data; })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(() => { fetchCategories(); });

      return {
        categoriesList,
        goalForm,
        disabledHandleChanges,

        onSubmit
      };
    }

  });
